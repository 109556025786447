import Model from './Model'
import Field from '../types/Field'
import Select from '../types/Select'

export default class User extends Model {
    endpoint = 'users';

    userTypes = {
      1: 'Administrador',
      2: 'Compañía',
      3: 'Agente',

    };

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('username', 'Correo electrónico'),
      new Field('password', 'Contraseña', 'password').hide(),
      new Select('type', 'Tipo de usuario', this.userTypes, 'admin').hide().disabled(),
    ];

    clone = () => User;
}
